.audio-player {
  @apply relative box-border overflow-hidden rounded-[10px] bg-black pb-11;
  direction: ltr;

  &.editable {
    @apply rounded-none h-full;
  }

  .video-player__volume::before,
  .video-player__seek::before {
    @apply bg-current opacity-[15];
  }

  .video-player__seek__buffer {
    @apply bg-current opacity-20;
  }

  .video-player__buttons button {
    @apply text-current opacity-[75];

    &:active,
    &:hover,
    &:focus {
      @apply text-current opacity-100;
    }
  }

  .video-player__time-sep,
  .video-player__time-total,
  .video-player__time-current {
    @apply text-current;
  }

  .video-player__seek::before,
  .video-player__seek__buffer,
  .video-player__seek__progress {
    @apply top-0;
  }

  .video-player__seek__handle {
    @apply -top-1;
  }

  .video-player__controls {
    @apply pt-2.5 bg-transparent;
  }
}

.media-spoiler-audio {
  @apply relative mt-2 block cursor-pointer border-0 bg-cover bg-center bg-no-repeat;
}
